
import { Component, Vue, Watch } from "vue-property-decorator";
import HospitalModule from "@/store/hospital";
import AuthModule from "@/store/auth";

@Component({})
export default class Header extends Vue {
  private routeName = this.$route.name;

  private get hospitalName() {
    return HospitalModule.hospitalName;
  }
  private get me() {
    return AuthModule.me;
  }

  @Watch("$route", { immediate: true, deep: true })
  private changeRoute() {
    if (this.$route.name) this.routeName = this.$route.name;
  }

  private get viewLoginBtn() {
    if (this.routeName == "SentRegister") {
      return true;
    } else {
      return false;
    }
  }
  private get viewMypageBtn() {
    if (this.me && this.routeName !== "MyPage") {
      return true;
    } else {
      return false;
    }
  }
  private get viewHeaderName() {
    if (
      this.routeName == "MyPage" ||
      this.routeName == "MyPageEdit" ||
      this.routeName == "MyPagePetEdit" ||
      this.routeName == "MyPagePetAdd" ||
      this.routeName == "MyPageReservationEdit"
    ) {
      return false;
    } else {
      return true;
    }
  }
}
