import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { User } from "@/@types/auth";
interface IAuthModule {
  _me: User | null;
}

@Module({
  dynamic: true,
  store,
  name: "auth",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).auth,
})
class AuthModule extends VuexModule implements IAuthModule {
  public _me: User | null = null;

  /**
   * 変更
   * @param user
   */
  @Mutation
  public changeMe(user: User | null) {
    this._me = user;
  }

  public get me() {
    return this._me || null;
  }
}
export default getModule(AuthModule);
