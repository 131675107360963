
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  name: "ResetPasswordSendConfirmed",
  components: {
    Header,
    Footer,
  },
})
export default class ResetPasswordSendConfirmed extends Vue {
  private back() {
    this.$router.push({ name: "Login" });
  }
}
