import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
interface IHospitalModule {
  _hospitalName: string;
  _hospitalId: string;
}

@Module({
  dynamic: true,
  store,
  name: "hospital",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).hospital,
})
class HospitalModule extends VuexModule implements IHospitalModule {
  public _hospitalId = "";
  public _hospitalName = "";

  /**
   * 変更
   * @param name
   */
  @Mutation
  public changeHospitalName(name: string) {
    this._hospitalName = name;
  }
  /**
   * 変更
   * @param id
   */
  @Mutation
  public changeHospitalId(id: string) {
    this._hospitalId = id;
  }

  public get hospitalName() {
    return this._hospitalName || "";
  }
  public get hospitalId() {
    return this._hospitalId || "";
  }
}
export default getModule(HospitalModule);
